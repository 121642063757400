'use strict'

const React = require('react')
const styles = require('./header.css')

module.exports = class ProductNav extends React.Component {
  render() {
    return (
      <nav className={styles.productNav} aria-label="Product Navigation">
        <ul id="main-menu" className="list pl0" role="menu" aria-label="Nav Menu">
          <li role="none" className="dib">
            <a
              href="/products/pro"
              role="menuitem"
              className={`${styles.productNavLink} dim pr2 pl2`}
              id="nav-pro-link"
            >
              Pro
            </a>
          </li>
          <li role="none">
            <a
              href="/products/teams"
              role="menuitem"
              className={`${styles.productNavLink} dim pr2 pl2`}
              id="nav-teams-link"
            >
              Teams
            </a>
          </li>
          <li role="none" className="dib">
            <a
              href="/products"
              role="menuitem"
              className={`${styles.productNavLink} dim pr2 pl2`}
              id="nav-pricing-link"
            >
              Pricing
            </a>
          </li>
          <li role="none" className="dib">
            <a
              href="https://docs.npmjs.com"
              role="menuitem"
              className={`${styles.productNavLink} dim pr2 pl2`}
              id="nav-docs-link"
            >
              Documentation
            </a>
          </li>
        </ul>
      </nav>
    )
  }
}
