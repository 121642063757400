'use strict'
const React = require('react')
const StaticComponent = require('../static')

class LoginLockIcon extends StaticComponent {
  render() {
    return (
      <svg width="34" height="44" viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8236 0C10.0013 0 4.4707 5.5306 4.4707 12.3529V15.9044C1.96244 16.1413 0 18.2532 0 20.8235V37.0588C0 39.7877 2.21224 42 4.94118 42H28.7059C31.4348 42 33.6471 39.7877 33.6471 37.0588V20.8235C33.6471 18.2533 31.6847 16.1415 29.1766 15.9044V12.3529C29.1766 5.5306 23.646 0 16.8236 0ZM24.2354 15.8823V12.3529C24.2354 8.25954 20.917 4.94118 16.8236 4.94118C12.7302 4.94118 9.41188 8.25954 9.41188 12.3529V15.8823H24.2354Z"
          fill="url(#paint0_linear_449_428)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8236 0C10.0013 0 4.4707 5.5306 4.4707 12.3529V15.9044C1.96244 16.1413 0 18.2532 0 20.8235V37.0588C0 39.7877 2.21224 42 4.94118 42H28.7059C31.4348 42 33.6471 39.7877 33.6471 37.0588V20.8235C33.6471 18.2533 31.6847 16.1415 29.1766 15.9044V12.3529C29.1766 5.5306 23.646 0 16.8236 0ZM24.2354 15.8823V12.3529C24.2354 8.25954 20.917 4.94118 16.8236 4.94118C12.7302 4.94118 9.41188 8.25954 9.41188 12.3529V15.8823H24.2354Z"
          fill="url(#paint1_linear_449_428)"
        />
        <path
          d="M4.94118 40.9998C2.21224 40.9998 0 38.7875 0 36.0586V39.0586C0 41.7875 2.21224 43.9998 4.94118 43.9998H28.7059C31.4348 43.9998 33.6471 41.7875 33.6471 39.0586V36.0586C33.6471 38.7875 31.4348 40.9998 28.7059 40.9998H4.94118Z"
          fill="#65C6C9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5551 28.4721C19.9918 27.8653 21 26.4432 21 24.7856C21 22.5765 19.2091 20.7856 17 20.7856C14.7909 20.7856 13 22.5765 13 24.7856C13 26.4432 14.0082 27.8653 15.4449 28.4721L13 36.2142H21L18.5551 28.4721Z"
          fill="url(#paint2_linear_449_428)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_449_428"
            x1="16.8235"
            y1="-1.85384e-07"
            x2="9.05882"
            y2="42"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0204316" stopColor="#F7CD58" />
            <stop offset="0.982597" stopColor="#F5B63F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_449_428"
            x1="16.8235"
            y1="0"
            x2="7"
            y2="39.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#65D2E5" />
            <stop offset="1" stopColor="#71D6D5" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_449_428"
            x1="17"
            y1="20.7856"
            x2="22.4286"
            y2="37.0714"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2E6954" />
            <stop offset="1" stopColor="#429083" />
          </linearGradient>
        </defs>
      </svg>
    )
  }
}

module.exports = LoginLockIcon
