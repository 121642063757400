const React = require('react')
const PropTypes = require('prop-types')

/**
 * A flexible stack component that arranges its children in a row or column.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child elements to be rendered inside the stack.
 * @param {'vertical' | 'horizontal'} props.direction - The direction in which to stack the children. Defaults to 'horizontal'.
 * @param {'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'} [props.justify='space-between'] - The CSS justify-content value.
 * @param {'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'} [props.align='center'] - The CSS align-items value.
 * @param {'full' | 'auto'} [props.width='full'] - The width of the stack container.
 * @param {number} [props.gap=0] - The gap between stack children.
 * @returns {JSX.Element} The rendered stack component.
 */
function Stack({children, direction, justify = 'space-between', align = 'center', width = 'full', gap = 0}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: direction === 'vertical' ? 'column' : 'row',
        justifyContent: justify,
        alignItems: align,
        width: width === 'full' ? '100%' : 'auto',
        gap,
      }}
    >
      {children}
    </div>
  )
}

Stack.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  justify: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
    'start',
    'end',
    'left',
    'right',
  ]),
  align: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'baseline',
    'stretch',
    'start',
    'end',
    'self-start',
    'self-end',
  ]),
  width: PropTypes.oneOf(['full', 'auto']),
  gap: PropTypes.number,
}

module.exports = {Stack}
