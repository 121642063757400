'use strict'
const React = require('react')
const StaticComponent = require('../static')

class InstallIcon extends StaticComponent {
  render() {
    return (
      <svg viewBox="0 0 11.64 5.82" height="6px" fill="#666" aria-hidden="true">
        <g id="6a308a62-c62e-4b78-87b6-759dd1ef54e5">
          <polygon points="10 0 5 5 0 0 10 0" />
        </g>
      </svg>
    )
  }
}

module.exports = InstallIcon
