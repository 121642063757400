
  const __registry__ = require('../../../../spiferack/client/index.js')
  'use strict';

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.symbol.to-primitive.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.date.to-primitive.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.string.starts-with.js");
require("core-js/modules/es.string.trim.js");
require("core-js/modules/web.dom-collections.iterator.js");
var isCidr4 = require('is-cidr').v4;
var moment = require('moment');
var _require = require('./constants'),
  TOKEN_NAME = _require.TOKEN_NAME,
  EXPIRATION_DAYS = _require.EXPIRATION_DAYS,
  EXPIRATION_ERROR = _require.EXPIRATION_ERROR,
  EXPIRY_ERROR = _require.EXPIRY_ERROR,
  ALLOWED_IP_RANGES = _require.ALLOWED_IP_RANGES,
  IP_RANGES_ERROR = _require.IP_RANGES_ERROR,
  PERMISSION_NO_ACCESS = _require.PERMISSION_NO_ACCESS,
  PERMISSION_READ_ONLY = _require.PERMISSION_READ_ONLY,
  PERMISSION_READ_WRITE = _require.PERMISSION_READ_WRITE,
  SELECTED_PACKAGES_AND_SCOPES_ALL = _require.SELECTED_PACKAGES_AND_SCOPES_ALL,
  TOKEN_DESCRIPTION = _require.TOKEN_DESCRIPTION,
  TOKEN_NAME_TOO_LONG_ERROR = _require.TOKEN_NAME_TOO_LONG_ERROR,
  TOKEN_DESCRIPTION_TOO_LONG_ERROR = _require.TOKEN_DESCRIPTION_TOO_LONG_ERROR,
  TOKEN_NAME_EMPTY_ERROR = _require.TOKEN_NAME_EMPTY_ERROR,
  MAX_TOKEN_NAME_LEN = _require.MAX_TOKEN_NAME_LEN,
  MAX_TOKEN_DESCRIPTION_LEN = _require.MAX_TOKEN_DESCRIPTION_LEN,
  TOKEN_NAME_CONTENT_ERROR = _require.TOKEN_NAME_CONTENT_ERROR,
  PACKAGES_AND_SCOPES_PERMISSION = _require.PACKAGES_AND_SCOPES_PERMISSION,
  ORGS_PERMISSION = _require.ORGS_PERMISSION,
  SELECTED_PACKAGES = _require.SELECTED_PACKAGES,
  SELECTED_SCOPES = _require.SELECTED_SCOPES,
  SELECTED_ORGS = _require.SELECTED_ORGS,
  SELECTED_PACKAGES_AND_SCOPES = _require.SELECTED_PACKAGES_AND_SCOPES,
  NO_PACKAGES_SELECTED_ERROR = _require.NO_PACKAGES_SELECTED_ERROR,
  NO_ORGS_SELECTED_ERROR = _require.NO_ORGS_SELECTED_ERROR,
  PACKAGES_AND_SCOPES = _require.PACKAGES_AND_SCOPES,
  ORGS = _require.ORGS,
  TOKEN_DESCRIPTION_CONTENT_ERROR = _require.TOKEN_DESCRIPTION_CONTENT_ERROR,
  PACKAGE_AND_SCOPES_NO_ACCESS_ERROR = _require.PACKAGE_AND_SCOPES_NO_ACCESS_ERROR,
  ORGS_NO_ACCESS_ERROR = _require.ORGS_NO_ACCESS_ERROR;
var validators = function validators(flags) {
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, TOKEN_NAME, function (formValues) {
    var tokenName = formValues[TOKEN_NAME].value;
    tokenName = tokenName.trim();
    if (tokenName.length === 0) {
      return {
        error: TOKEN_NAME_EMPTY_ERROR
      };
    }
    if (tokenName.length > MAX_TOKEN_NAME_LEN) {
      return {
        error: TOKEN_NAME_TOO_LONG_ERROR
      };
    }
    return {};
  }), TOKEN_DESCRIPTION, function (formValues) {
    var tokenDescription = formValues[TOKEN_DESCRIPTION].value;
    tokenDescription = tokenDescription.trim();
    if (tokenDescription.length === 0) {
      return {};
    }
    if (tokenDescription.length > MAX_TOKEN_DESCRIPTION_LEN) {
      return {
        error: TOKEN_DESCRIPTION_TOO_LONG_ERROR
      };
    }
    return {};
  }), EXPIRATION_DAYS, function (formValues) {
    var expirationDays = formValues[EXPIRATION_DAYS].value;
    if (flags.isExtendedGATExpiryEnabled) {
      var valid = Number(expirationDays) >= 1;
      if (!valid) {
        return {
          error: EXPIRY_ERROR
        };
      }
    } else {
      var _valid = Number(expirationDays) >= 1 && Number(expirationDays) <= 365;
      if (!_valid) {
        return {
          error: EXPIRATION_ERROR
        };
      }
    }
    return {};
  }), ALLOWED_IP_RANGES, function (formValues) {
    var allowedIPRanges = formValues[ALLOWED_IP_RANGES].value;
    var valid = true;
    for (var i = 0; i < allowedIPRanges.length; i++) {
      if (allowedIPRanges[i] === '' || allowedIPRanges[i] === undefined) {
        continue;
      }
      if (!isCidr4(allowedIPRanges[i])) {
        valid = false;
        break;
      }
    }
    if (!valid) {
      return {
        error: IP_RANGES_ERROR
      };
    }
    return {};
  }), PACKAGES_AND_SCOPES, function (formValues) {
    var permission = formValues[PACKAGES_AND_SCOPES_PERMISSION].value;
    var selectedPkgsAndScopes = formValues[SELECTED_PACKAGES_AND_SCOPES].value;
    var selectedScopes = formValues[SELECTED_SCOPES].value;
    var selectedPkgs = formValues[SELECTED_PACKAGES].value;
    if (permission !== PERMISSION_NO_ACCESS && selectedPkgsAndScopes !== SELECTED_PACKAGES_AND_SCOPES_ALL && selectedScopes.length === 0 && selectedPkgs.length === 0) {
      return {
        error: NO_PACKAGES_SELECTED_ERROR
      };
    }
    return {};
  }), ORGS, function (formValues) {
    var permission = formValues[ORGS_PERMISSION].value;
    var selectedOrgs = formValues[SELECTED_ORGS].value;
    if (permission !== PERMISSION_NO_ACCESS && selectedOrgs.length === 0) {
      return {
        error: NO_ORGS_SELECTED_ERROR
      };
    }
    return {};
  }), PACKAGES_AND_SCOPES_PERMISSION, function (formValues) {
    if (formValues[PACKAGES_AND_SCOPES_PERMISSION].value === PERMISSION_NO_ACCESS && formValues[ORGS_PERMISSION].value === PERMISSION_NO_ACCESS) {
      return {
        error: PACKAGE_AND_SCOPES_NO_ACCESS_ERROR
      };
    }
    return {};
  }), ORGS_PERMISSION, function (formValues) {
    if (formValues[PACKAGES_AND_SCOPES_PERMISSION].value === PERMISSION_NO_ACCESS && formValues[ORGS_PERMISSION].value === PERMISSION_NO_ACCESS) {
      return {
        error: ORGS_NO_ACCESS_ERROR
      };
    }
    return {};
  });
};
function permissionPhrase(permission) {
  switch (permission) {
    case PERMISSION_NO_ACCESS:
      return 'no';
    case PERMISSION_READ_ONLY:
      return 'read-only';
    case PERMISSION_READ_WRITE:
      return 'read and write';
    default:
      return 'no';
  }
}

// Works for the words - scope, package, organization.
function pluralize(word, count) {
  return count === 1 ? word : "".concat(word, "s");
}

// Return the date after the `days` days in the format 'Monday, January 1, 2020'.
// startDate is the date to start from, in the format 'YYYY-MM-DD'.
function dateAfterDays(startDate, days) {
  var newDate = moment(startDate, 'YYYY-MM-DD').add(days, 'days');
  return newDate.format('dddd, MMMM D, YYYY');
}

// If permission is No access, count should be empty.
// If all packages/scopes/orgs are selected then all should be the corresponding count phrase.
// Else, the actual count of the items.
function packagesCountPhrase(permission, selectedPackagesAndScopes, selectedCount) {
  if (permission === PERMISSION_NO_ACCESS) {
    return '';
  }
  if (selectedPackagesAndScopes === SELECTED_PACKAGES_AND_SCOPES_ALL) {
    return 'all';
  }
  return selectedCount;
}
function countPhrase(permission, selectedCount) {
  if (permission === PERMISSION_NO_ACCESS) {
    return '';
  }
  return selectedCount;
}
function pkgsAndScopesCountPhrase(pkgsPhrase, scopesPhrase) {
  // Only some scopes selected
  if (pkgsPhrase.startsWith('0') && !scopesPhrase.startsWith('0')) {
    return scopesPhrase;
  }
  // Only some packages selected
  if (!pkgsPhrase.startsWith('0') && scopesPhrase.startsWith('0')) {
    return pkgsPhrase;
  }
  // Either some scopes + packages selected
  // or none selected (which shouldn't happen)
  return pkgsPhrase + ' and ' + scopesPhrase;
}

// Unscoped packages comes first, then scoped packages.
function pkgCompareFn(pkg1, pkg2) {
  if (pkg1.startsWith('@') && !pkg2.startsWith('@')) {
    return 1;
  }
  if (!pkg1.startsWith('@') && pkg2.startsWith('@')) {
    return -1;
  }
  return pkg1.localeCompare(pkg2);
}
function scopeCompareFn(scope1, scope2) {
  return scope1.localeCompare(scope2);
}
function orgCompareFn(org1, org2) {
  return org1.name.localeCompare(org2.name);
}
module.exports = {
  validators: validators,
  permissionPhrase: permissionPhrase,
  pluralize: pluralize,
  dateAfterDays: dateAfterDays,
  packagesCountPhrase: packagesCountPhrase,
  countPhrase: countPhrase,
  pkgsAndScopesCountPhrase: pkgsAndScopesCountPhrase,
  pkgCompareFn: pkgCompareFn,
  scopeCompareFn: scopeCompareFn,
  orgCompareFn: orgCompareFn
};
  let __hot__
  
  __registry__.register('tokens/gat/utils', module.exports, __hot__)
  