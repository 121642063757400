'use strict'

module.exports = {
  FORM_ID: 'audit-log-search',
  MAX_QUERY_LEN: 500,

  ERROR_QUERY_TOO_LONG: 'query too long',
  ERROR_INVALID_PACKAGE: 'invalid package',
  ERROR_INVALID_ORG: 'invalid org',
  ERROR_INVALID_ACTIVITY: 'invalid activity',
  ERROR_INVALID_IP: 'invalid ip',
  ERROR_INVALID_DATE: 'invalid date',
}
